import { defaultStyles as defaultCalendarStyles } from '../../../components/calendar/_styles';
import { DatePickerInputStylesNames } from '@mantine/dates';

import { Styles } from '@mantine/core';

export const defaultStyles: Styles<DatePickerInputStylesNames, never> = {
  input: {
    borderColor: '#CED0D780',
    borderRadius: 8,
    '&:focus, &:focus-within': {
      border: '1px solid #BEC0C9',
      boxShadow: '0px 0px 0px 1px #BEC0C9',
    },
    '&::-webkit-input-placeholder, &::placeholder, &': {
      fontSize: 13,
      fontWeight: 500,
      color: '#5C6178',
    },
    '&::-webkit-input-placeholder, &::placeholder': {
      color: '#BEC0C9',
    },
  },
  ...defaultCalendarStyles,
};

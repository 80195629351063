import { Styles } from '@mantine/core';
import { CalendarStylesNames } from '@mantine/dates';

export const defaultStyles: Styles<CalendarStylesNames, any> = {
  day: {
    '& :hover': {
      backgroundColor: '#5C617810 ',
    },
    '& [data-selected]': {
      borderRadius: '7px !important',
      backgroundColor: '#222222 !important ',
    },
    '& [data-in-range]': {
      backgroundColor: '#5C617810 !important ',
    },
    '& [data-first-in-range]': {
      borderRadius: '7px 0 0 7px !important',
      backgroundColor: '#222222 !important ',
    },
    '& [data-last-in-range]': {
      borderRadius: '0 7px 7px 0 !important',
      backgroundColor: '#222222 !important ',
    },
  },
  calendarHeaderLevel: {
    borderRadius: '7px !important',
  },
};

import { ActionIcon, ActionIconProps, Sx } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export const IconButton = forwardRef(
  (
    {
      children,
      sx,
      component,
      ...props
    }: ActionIconProps & {
      sx?: Sx;
      component?: any;
      href?: string;
      target?: string;
      to?: string;
    } & ComponentPropsWithoutRef<'button'>,
    ref
  ) => {
    return (
      <ActionIcon
        ref={ref}
        variant='transparent'
        component={component}
        sx={{
          borderRadius: '20%',
          ':hover': {
            backgroundColor: '#00000000',
          },
          ...sx,
        }}
        {...props}
      >
        {children}
      </ActionIcon>
    );
  }
);

IconButton.displayName = 'IconButton';

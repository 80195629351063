import { IconButton, Logo, Select, SelectItem } from '../';
import { StringParam, useQueryParam } from '../../hooks';
import { TeamSelectChevronsIcon } from './_icons';
import { Box, Group, Stack, Text } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { RefObject, forwardRef, useEffect, useMemo, useState } from 'react';

type TeamSelectItem = SelectItem & { teamType: string };

type TeamSwitchProps = {
  isNavigationExpanded: boolean;
  team?: { logo?: string; name: string; id: string };
  teams: { id: string; name: string; type?: string }[];
  setTeam: (id: string) => void;
  logo?: string;
  onLogoClick?: () => void;
  addTeam?: (teamName: string) => void;
  canSwitch: boolean;
  hideLogo?: boolean;
};

export const TeamSwitch = ({
  isNavigationExpanded,
  teams,
  team,
  setTeam,
  logo,
  onLogoClick,
  addTeam,
  canSwitch,
  hideLogo,
}: TeamSwitchProps) => {
  const [sharedTeamId] = useQueryParam('sti', StringParam);

  const [opened, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen((e) => !e);

  const activeTeam = useMemo<SelectItem>(
    () => ({ label: team?.name || '', value: team?.id || '' }),
    [team?.id]
  );

  const selectOptions = useMemo<TeamSelectItem[]>(
    () =>
      teams.map((t) => ({
        label: t.name,
        value: t.id,
        pinned: t.type !== 'propertyManager',
        teamType: t.type || '',
      })),
    [teams]
  );

  useHotkeys([['mod+J', toggle]]);

  // Update team and reset query param
  useEffect(() => {
    if (sharedTeamId) {
      setTeam(sharedTeamId);
    }
  }, [sharedTeamId]);

  return (
    <Group
      noWrap
      sx={{
        flex: 1,
        justifyContent: isNavigationExpanded ? 'flex-start' : 'center',
        flexDirection: isNavigationExpanded ? undefined : 'column',
      }}
    >
      {!hideLogo && (
        <Box
          onClick={onLogoClick}
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            borderRadius: 10,
            cursor: onLogoClick ? 'pointer' : undefined,
          }}
        >
          <Logo img={logo} />
        </Box>
      )}
      <Stack
        spacing={0}
        sx={{
          color: '#fff',
          flex: isNavigationExpanded ? 1 : 0,
        }}
      >
        {isNavigationExpanded && !hideLogo && (
          <Text sx={{ opacity: 0.8 }} size='xs'>
            Team
          </Text>
        )}
        <Select
          opened={opened}
          onClose={close}
          data={selectOptions}
          value={activeTeam}
          withSearch
          sort={'asc'}
          onAddCustomValue={addTeam ? (v) => addTeam(v.label) : undefined}
          addOptionText={() => 'Create new team'}
          setValue={(v: any) => setTeam(v.value)}
          removeValue={() => null}
          popoverWidth={250}
          offset={10}
          position='bottom'
          withArrow={false}
          searchPlaceholder='Search teams...'
          noOptionsText='No teams found...'
          customTarget={
            <PopoverButton
              teamName={team?.name}
              canSwitch={canSwitch}
              isNavigationExpanded={isNavigationExpanded}
              togglePopover={toggle}
            />
          }
        />
      </Stack>
    </Group>
  );
};

const PopoverButton = forwardRef(
  (
    {
      isNavigationExpanded,
      canSwitch,
      togglePopover,
      teamName,
    }: {
      teamName?: string;
      isNavigationExpanded: boolean;
      canSwitch: boolean;
      togglePopover: () => void;
    },
    ref
  ) => (
    <Group
      noWrap
      position='apart'
      spacing={0}
      ref={ref as RefObject<HTMLDivElement>}
      pb={4}
      pl={isNavigationExpanded ? 8 : 0}
      ml={isNavigationExpanded ? -8 : 0}
      onClick={canSwitch ? togglePopover : undefined}
      sx={{
        borderRadius: 7,
        maxWidth: 160,
        display: !isNavigationExpanded && !canSwitch ? 'none' : undefined,
        cursor: canSwitch ? 'pointer' : 'default',
        ':hover': {
          backgroundColor: canSwitch ? '#5C617840' : undefined,
        },
        '&, *': {
          overflowY: 'hidden',
        },
      }}
    >
      {isNavigationExpanded && (
        <Text
          component='span'
          mb={-4}
          sx={{
            maxWidth: '100%',
            display: 'block',
            height: 20,
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: 'normal',
          }}
        >
          {teamName}
        </Text>
      )}

      {canSwitch ? (
        <IconButton
          component='span'
          mb={-5}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <TeamSelectChevronsIcon />
        </IconButton>
      ) : null}
    </Group>
  )
);

PopoverButton.displayName = 'PopoverButton';

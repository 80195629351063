import { useMantineTheme } from '@mantine/core';

export const useColors = () => {
  const { colors: themeColors } = useMantineTheme();

  return {
    themeColors,
    green: {
      base: themeColors.green[6], // old: '#26BF66'
    },
    red: {
      base: themeColors.red[6],
    },
    blue: {
      light: '#0EA5E9',
    },
    black: {
      base: '#141719',
    },
    purple: {
      base: '#8330D5',
      light: '#6c29af',
    },
    gold: {
      base: '#FFD700',
    },
    gray: {
      dark: '#5C6178',
      light: '#D9D9D9',
    },
    border: {
      gray: '#E6E7EA',
      input: '#CED0D7',
    },
  };
};

// https://coolors.co/446187-55776b-66479e-994e5f-bc9e24-a737be-9e7042-707786-000000
export const OWNER_PORTAL_COLORS = {
  /*
  blue: '#446187',
  green: '#55776B',
  purple: '#66479E',
  red: '#994E5F',
  yellow: '#BC9E24',
  pink: '#A737BE',
  brown: '#4e342e',
  black: '#22201f',
  gray: '#707786',
  */
  orange: '#9A3412',
  teal: '#004d40',
  indigo: '#1D3783',
  brown: '#4e342e',
  green: '#3a5d57',
  blue: '#15638f',
  gray: '#707786',
  black: '#22201f',
  red: '#EB144C',
  violett: '#9900EF',
  deepPurple: '#4527a0',
  purple: '#6a1b9a',
};

export function useOwnerPortalColor(
  col?: keyof typeof OWNER_PORTAL_COLORS | string
): string {
  const { colors: themeColors, primaryColor } = useMantineTheme();

  return (
    (OWNER_PORTAL_COLORS as any)[col || ''] || themeColors[primaryColor][8]
  );
}

import {
  MultiSelectButton,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  SingleSelectButton,
} from './_components';
import { SelectItem } from './_types';
import { Box, Popover as MantinePopover } from '@mantine/core';
import { ReactNode, useMemo, useState } from 'react';

type DefaultSelectProps = {
  withBorder?: boolean;
  placeholder?: string;
  preventClose?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  customTarget?: ReactNode;
  data: SelectItem[];
  opened?: boolean;
  setValue: (value: SelectItem) => void;
  removeValue: (value: SelectItem) => void;
  filterSelectedValues?: boolean;
  loading?: boolean;
  offset?: number | undefined;
  disabled?: boolean;
  inputLoading?: boolean;
};

type SingleSelectProps = {
  multiple?: false;
  value?: SelectItem;
};
type MultiSelectProps = {
  multiple: true;
  value: SelectItem[];
};

type SelectProps = DefaultSelectProps &
  PopoverProps &
  PopoverContentProps &
  (SingleSelectProps | MultiSelectProps);

export const Select = ({
  withSearch,
  data,
  value,
  popoverWidth = 350,
  withBorder = false,
  placeholder,
  preventClose = false,
  defaultOpen = false,
  onClose,
  customTarget,
  opened: customOpened,
  multiple,
  setValue,
  removeValue,
  filterSelectedValues,
  onAddCustomValue,
  sort = 'asc',
  loading,
  onSearchInput,
  noOptionsText,
  searchPlaceholder,
  addOptionText,
  popoverHeight,
  disabled,
  inputLoading,
  ...popoverRestProps
}: SelectProps) => {
  const [opened, setOpened] = useState(defaultOpen);

  const close = () => {
    if (onClose) onClose();
    setOpened(false);
  };

  const toggle = () => (!disabled ? setOpened((o) => !o) : null);

  const o = customOpened || opened;

  const filtered = useMemo<SelectItem[]>(() => {
    if (!filterSelectedValues) return data;

    if (multiple) {
      return data.filter((i) => !value.some((t) => t.value === i.value));
    } else {
      return data.filter((i) => i.value !== value?.value);
    }
  }, [data, filterSelectedValues, value, multiple]);

  const addNewValue = !onAddCustomValue
    ? undefined
    : (newValue: SelectItem) => {
        onAddCustomValue(newValue);
        if (!preventClose) close();
      };

  return (
    <Popover
      opened={o}
      onClose={close}
      popoverWidth={popoverWidth}
      {...popoverRestProps}
    >
      <MantinePopover.Target>
        {customTarget ? (
          typeof customOpened !== 'undefined' ? (
            customTarget
          ) : (
            <Box onClick={toggle} sx={{ cursor: 'pointer' }}>
              {customTarget}
            </Box>
          )
        ) : multiple ? (
          <MultiSelectButton
            opened={o}
            activeValues={value}
            onClick={toggle}
            withBorder={withBorder}
            onRemoveClicked={removeValue}
            placeholder={placeholder}
            disabled={!!disabled}
            loading={!!inputLoading}
          />
        ) : (
          <SingleSelectButton
            label={(value as SelectItem)?.label || ''}
            onClick={toggle}
            opened={opened}
            withBorder={withBorder}
            placeholder={placeholder}
            disabled={!!disabled}
            loading={!!inputLoading}
          />
        )}
      </MantinePopover.Target>
      <MantinePopover.Dropdown>
        {multiple ? (
          <PopoverContent
            setValue={setValue}
            data={filtered}
            withSearch={withSearch}
            onAddCustomValue={addNewValue}
            onSearchInput={onSearchInput}
            noOptionsText={noOptionsText}
            searchPlaceholder={searchPlaceholder}
            addOptionText={addOptionText}
            popoverHeight={popoverHeight}
            sort={sort}
            loading={loading}
          />
        ) : (
          <PopoverContent
            activeValue={value as SelectItem}
            setValue={(v: SelectItem) => {
              if (v.value === (value as SelectItem)?.value) {
                removeValue(v);
              } else {
                setValue(v);
              }
              if (!preventClose) close();
            }}
            data={filtered}
            withSearch={withSearch}
            onAddCustomValue={addNewValue}
            onSearchInput={onSearchInput}
            noOptionsText={noOptionsText}
            searchPlaceholder={searchPlaceholder}
            addOptionText={addOptionText}
            popoverHeight={popoverHeight}
            sort={sort}
            loading={loading}
          />
        )}
      </MantinePopover.Dropdown>
    </Popover>
  );
};

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(relativeTime);

export * from './components';
export * from './icons';
export * from './styles';
export * from './hooks';
export * from './utils';
export * from './extension';

import { Button as MantineButton, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type Props = ButtonProps & {
  component?: any;
  href?: string;
  target?: string;
  to?: string;
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef(
  ({ children, leftIcon, sx, component = 'button', ...props }: Props, ref) => {
    return (
      <MantineButton
        component={component}
        variant='outline'
        color='dark'
        radius={10}
        px='sm'
        leftIcon={leftIcon}
        sx={{
          borderColor: '#EEEFF1',
          ...sx,
        }}
        {...props}
        ref={ref}
      >
        {children}
      </MantineButton>
    );
  }
);

Button.displayName = 'Button';

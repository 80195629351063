import { FormulaField } from './_types';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Group,
  ScrollArea,
  Stack,
  Sx,
  Text,
  Tooltip,
} from '@mantine/core';
import { Fragment, MouseEventHandler, Ref, forwardRef } from 'react';

type PopoverContentProps = {
  data: FormulaField[];
  onAdd: (value: FormulaField) => void;
  containerRef: Ref<HTMLDivElement>;
  scrollableRef: Ref<HTMLDivElement>;
  itemRefs: { [key: string]: HTMLButtonElement };
  setHighlightedIndex: (value: number) => void;
  highlightedIndex: number | undefined;
};

export const InputFormulaContent = ({
  onAdd,
  data,
  containerRef,
  scrollableRef,
  itemRefs,
  highlightedIndex,
  setHighlightedIndex,
}: PopoverContentProps) => {
  return (
    <Box
      sx={{
        maxHeight: 230,
        display: 'flex',
      }}
      ref={containerRef}
    >
      <ScrollArea
        viewportRef={scrollableRef}
        style={{
          flex: 1,
        }}
      >
        <Stack spacing={5}>
          {data.length > 0 ? (
            data.map((item, index, arr) => {
              return (
                <Fragment key={`${item.id}-${index}`}>
                  <Tooltip
                    label={item.description}
                    disabled={!item.description}
                    opened={highlightedIndex === index}
                    position='left'
                    withinPortal
                    withArrow
                  >
                    <SelectButton
                      onClick={() => onAdd(item)}
                      label={item.label}
                      onMouseEnter={() => setHighlightedIndex(index)}
                      isFocused={index === highlightedIndex}
                      ref={(node: HTMLButtonElement) => {
                        if (itemRefs?.current) {
                          itemRefs.current = {
                            ...itemRefs.current,
                            [item.id]: node,
                          };
                        }
                      }}
                      itemType={item.type}
                      wrapText
                    />
                  </Tooltip>
                  {item?.type !== arr[index + 1]?.type && (
                    <Divider sx={{ opacity: 0.8 }} />
                  )}
                </Fragment>
              );
            })
          ) : (
            <Text my={6} align='center' size={13} color='gray' weight={500}>
              No options available...
            </Text>
          )}
        </Stack>
      </ScrollArea>
    </Box>
  );
};

const SelectButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onClick: (value: string) => void;
    label: string;
    sx?: Sx;
    wrapText?: boolean;
    itemType: string;
    isFocused?: boolean;
  } & any
>(
  (
    {
      onClick,
      label,
      wrapText = false,
      sx,
      isFocused = false,
      className,
      itemType,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        variant='subtle'
        onClick={onClick as unknown as MouseEventHandler<HTMLButtonElement>}
        px={5}
        className={`${isFocused ? 'highlighted' : ''} ${className}`}
        py={2}
        radius={10}
        sx={{
          width: '100%',
          height: '100%',
          '&:hover, &.highlighted': {
            backgroundColor: '#5C617820',
          },
          ...sx,
        }}
        styles={{
          label: {
            fontWeight: 500,
            color: '#5C6178',
            fontSize: 12,
            width: '100%',
            display: 'inline',
            whiteSpace: wrapText ? 'normal' : 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            lineHeight: 'normal',
          },
        }}
        {...props}
      >
        <Group position='apart' noWrap>
          {label}
          <Text sx={{ flexShrink: 0 }}># {itemType}</Text>
        </Group>
      </Button>
    );
  }
);

SelectButton.displayName = 'SelectButton';

export const TeamSelectChevronsIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <path
        d='M9.66683 15.8333L12.0002 18.1666L14.3335 15.8333'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g clipPath='url(#clip0_140_14863)'>
        <path
          d='M14.3332 8.16669L11.9998 5.83335L9.6665 8.16669'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_140_14863'>
        <rect
          width='8'
          height='8'
          fill='white'
          transform='translate(16 11) rotate(-180)'
        />
      </clipPath>
    </defs>
  </svg>
);

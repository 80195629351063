import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'; // @ag-grid-community/core will always be implicitly available
import { ModuleRegistry } from '@ag-grid-community/core';
import { EnterpriseCoreModule, LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
  EnterpriseCoreModule,
  ServerSideRowModelModule,
  RowGroupingModule,
  RangeSelectionModule,
  ExcelExportModule,
  MenuModule,
]);

export * from '@ag-grid-community/core';
export { AgGridReact } from '@ag-grid-community/react';
export type { AgGridReactProps } from '@ag-grid-community/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
// import '@ag-grid-community/styles/ag-theme-material.css';

LicenseManager.setLicenseKey(
  `
  CompanyName=Finalytic Ventures Ltd,LicensedApplication=Finalytic.,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-029349,SupportServicesEnd=16_December_2023_[v2]_MTcwMjY4NDgwMDAwMA==cc8869558251bd98a0137faf4097861d
  `.trim()
);

import { Select, SelectItem } from '../../select';
import { ListingMappingRow, ListingMappingTableContext } from './_types';
import { ICellEditorParams, ICellRendererParams } from '@finalytic/ui-grid';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const SelectListingMappingCell = forwardRef(
  (params: ICellEditorParams & { selectOptions: SelectItem[] }, ref) => {
    const { addNewSelectOption, selectOptions }: ListingMappingTableContext =
      params.context;

    const value: SelectItem | undefined =
      selectOptions.find((i) => i.value === params?.value) || undefined;

    const [newValue, setNewValue] = useState(value);

    const setValue = (newValue: SelectItem) => setNewValue(newValue);
    const removeValue = () => setNewValue(undefined);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return newValue?.value;
        },
      };
    });

    useEffect(() => {
      if (newValue?.value !== params.value) {
        params.api.stopEditing();
      }
    }, [newValue]);

    const addCustomValue = (value: SelectItem) => {
      addNewSelectOption(value);
      setNewValue(value);
    };

    return (
      <Select
        onAddCustomValue={addCustomValue}
        data={selectOptions}
        value={value}
        withSearch
        setValue={setValue}
        removeValue={removeValue}
        popoverWidth='target'
        onClose={() => params.api.stopEditing()}
        preventClose
        defaultOpen
      />
    );
  }
);

SelectListingMappingCell.displayName = 'SelectListingMappingCell';

export const ListingMappingCell = (
  params: ICellRendererParams<ListingMappingRow>
) => {
  const label = params.context.selectOptions.find(
    (i: SelectItem) => i.value === params.value
  )?.label;
  return (
    <Group position='apart' noWrap sx={{ height: '100%' }}>
      <Text lineClamp={1} sx={{ display: 'block' }}>
        {label}
      </Text>
      <ThemeIcon color='dark' variant='outline' sx={{ border: 'none' }}>
        <FontAwesomeIcon size='sm' icon={faChevronDown} />
      </ThemeIcon>
    </Group>
  );
};

import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Center, Input, InputProps } from '@mantine/core';
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';

export type InputSearchProps = {
  reset?: () => void;
} & InputProps &
  ComponentPropsWithoutRef<'input'>;

export const InputSearch = ({
  sx,
  reset,
  autoFocus,
  ...props
}: InputSearchProps) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [autoFocus]);

  return (
    <Input
      ref={ref as any}
      placeholder='Search'
      radius={10}
      variant='filled'
      size='sm'
      autoCorrect='off'
      icon={
        <Center pl={3}>
          <FontAwesomeIcon icon={faMagnifyingGlass} size='xs' />
        </Center>
      }
      rightSection={
        props.value && reset ? (
          <ActionIcon size='sm' onClick={reset}>
            <FontAwesomeIcon icon={faXmark} size='sm' />
          </ActionIcon>
        ) : (
          <></> // Without this the input right section jumps onChange
        )
      }
      sx={{
        '& input': {
          backgroundColor: '#F0F0F2',
          '&::placeholder': {
            fontSize: '12px',
            color: '#5C6178',
          },
          '&:focus': {
            borderColor: '#5C617830!important',
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
};

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, Sx } from '@mantine/core';
import { MouseEventHandler, forwardRef } from 'react';

export type SelectButtonProps = ButtonProps & {
  onClick: (value: string) => void;
  isActive?: boolean;
  label: string;
  sx?: Sx;
  wrapText?: boolean;
  isFocused?: boolean;
  autoFocus?: boolean;
  onMouseEnter?: () => void;
};

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  (
    {
      onClick,
      isActive,
      label,
      wrapText = false,
      sx,
      isFocused = false,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        variant='subtle'
        onClick={onClick as unknown as MouseEventHandler<HTMLButtonElement>}
        rightIcon={
          isActive && (
            <FontAwesomeIcon icon={faCheck} size='sm' color='#5C6178' />
          )
        }
        px={5}
        className={`${isFocused ? 'highlighted' : ''} ${className}`}
        py={8}
        radius={10}
        sx={{
          width: '100%',
          height: '100%',
          '&:hover, &.highlighted': {
            backgroundColor: '#5C617820',
          },
          ...sx,
        }}
        styles={{
          label: {
            fontWeight: 500,
            color: '#5C6178',
            fontSize: 13,
            width: '100%',
            display: 'inline',
            whiteSpace: wrapText ? 'normal' : 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            lineHeight: 'normal',
          },
        }}
        {...props}
      >
        {label}
      </Button>
    );
  }
);

SelectButton.displayName = 'SelectButton';

import { useColors } from '../../../styles';
import {
  IconDefinition,
  faEllipsis,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Menu, MenuItemProps, MenuProps } from '@mantine/core';
import { ReactElement, ReactNode } from 'react';

type EllipsisMenuItemProps = Omit<MenuItemProps, 'icon'> & {
  icon?: IconDefinition;
  component?: any;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  iconColor?: string;
  customIcon?: ReactElement;
};

export const EllipsisMenuDivider = Menu.Divider;

export const EllipsisMenuItem = ({
  children,
  icon,
  iconColor,
  customIcon,
  ...props
}: EllipsisMenuItemProps) => (
  <Menu.Item
    icon={customIcon || (icon && <Icon icon={icon} color={iconColor} />)}
    {...props}
  >
    {children}
  </Menu.Item>
);

export const EllipsisMenuDangerItem = (props: EllipsisMenuItemProps) => {
  const { red } = useColors();

  return (
    <EllipsisMenuItem
      {...props}
      icon={faTrashAlt}
      sx={{ color: red.base }}
      iconColor={props.iconColor || red.base}
    />
  );
};

export const EllipsisMenuLabel = Menu.Label;

type Props = { children: ReactNode } & MenuProps;

export const EllipsisMenu = ({
  children,
  trigger = 'hover',
  ...menuProps
}: Props) => {
  const { gray } = useColors();

  return (
    <Menu
      shadow='md'
      width={200}
      radius='md'
      position='bottom-end'
      withArrow
      withinPortal
      trigger={trigger}
      styles={() => ({
        item: {
          height: 35,
        },
        label: {
          color: gray.dark,
          textTransform: 'uppercase',
        },
        arrow: {
          right: '10px !important',
        },
      })}
      {...menuProps}
    >
      <Menu.Target>
        <ActionIcon disabled={menuProps.disabled}>
          <Icon icon={faEllipsis} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};

const Icon = ({ icon, color }: { icon: IconDefinition; color?: string }) => {
  const { gray } = useColors();

  return <FontAwesomeIcon width={15} color={color || gray.dark} icon={icon} />;
};

import { useColors } from '../../styles';
import {
  Center,
  Loader,
  LoaderProps,
  MantineNumberSize,
  Stack,
  Text,
} from '@mantine/core';

type LoadingIndicatorProps = {
  isFullPageLoading?: boolean;
  message?: string;
  size?: MantineNumberSize;
};

export const LoadingIndicator = ({
  isFullPageLoading = false,
  message,
  size = 'lg',
  ...loaderProps
}: LoadingIndicatorProps & LoaderProps) => {
  const { purple } = useColors();

  const Loading = <Loader color={purple.base} size={size} {...loaderProps} />;

  const WithMessage = (
    <Stack justify='center' align='center'>
      {Loading}
      {message ? <Text size='xl'>{message}</Text> : null}
    </Stack>
  );

  if (isFullPageLoading)
    return (
      <Center sx={{ minHeight: '40vh', textAlign: 'center' }}>
        {WithMessage}
      </Center>
    );

  return message ? WithMessage : Loading;
};

import { useColors } from '../../styles';
import { Image, useMantineTheme } from '@mantine/core';

const ximplifiLogo =
  'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/1e2027e6-65e2-41a9-93bc-2343a0739700/public';

export const Logo = ({
  color,
  img,
  width = 30,
  background = 'white',
}: {
  color?: `#${string}`;
  img?: string | null;
  width?: number;
  background?: string;
}) => {
  const { black } = useColors();
  const { primaryColor: appName } = useMantineTheme();
  const cl = color || black.base || 'white';

  if (img) {
    return (
      <Image
        src={img}
        width={width}
        height={width}
        fit='contain'
        withPlaceholder
        alt='Team logo'
      />
    );
  }

  if (appName === 'VRPlatform')
    return (
      <Image
        src={ximplifiLogo}
        width={width}
        height={width}
        fit='contain'
        withPlaceholder
        alt='Team logo'
        sx={{ background }}
      />
    );

  return (
    <svg
      width={width}
      height={width}
      viewBox='0 0 200 203'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='200' height='200' rx='10' fill='transparent' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.427 152.426L86.0002 110L100.142 95.8579L142.569 138.284L128.427 152.426Z'
        fill={cl}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.1422 138L72 152.142L57.8579 138L72 123.858L86.1422 138Z'
        fill={cl}
      />
      <path
        d='M43 125.569L28.8579 111.426L85.4265 54.8579L99.5001 41L169.853 111.569L155.711 125.711L99.5686 69L43 125.569Z'
        fill={cl}
      />
    </svg>
  );
};

import { createStyles } from '@mantine/core';

export const gridBorderRadius = '20px';

export const useStyles = createStyles(
  (
    theme,
    {
      isEmpty,
      isBottomRounded,
      isTopRounded,
      noBorder,
      minHeight,
      noHeader,
      hasRowAutoHeight,
    }: {
      isEmpty: boolean;
      isBottomRounded: boolean;
      isTopRounded: boolean;
      noBorder: boolean;
      minHeight: number;
      noHeader: boolean;
      hasRowAutoHeight: boolean;
    }
  ) => {
    return {
      table: {
        '&.ag-theme-alpine': {
          // Overwrite AG Grid Variables
          '--ag-grid-min-height': '250px',
          '--ag-border-color': '#e6e8ed',
          '--ag-range-selection-border-color': '#969696',
          '--ag-checkbox-unchecked-color': '#96969680',

          '.ag-center-cols-viewport::-webkit-scrollbar': {
            height: 0,
          },

          '.ag-cell-wrapper': hasRowAutoHeight
            ? undefined
            : {
                height: '100%',
              },
          // removing header bottom border
          '& .ag-header': noHeader
            ? {
                borderBottom: 'none',
              }
            : undefined,

          // Allow table with just one row and no minHeight in that case
          '& .ag-center-cols-clipper, .ag-center-cols-container': {
            minHeight: isEmpty
              ? minHeight
                ? `${minHeight} !important`
                : undefined
              : `${minHeight} !important`,
          },

          '.ag-overlay-no-rows-wrapper.ag-layout-auto-height': {
            paddingTop: 0,
          },

          '& .ag-root-wrapper': {
            height: '100%',
            // This fixes the border-radius overflow issue on Safari
            WebkitMaskImage: '-webkit-radial-gradient(white, black)',

            // Conditional border
            border: noBorder ? 'none' : undefined,

            // Conditional Top & Bottom border radius
            borderTopLeftRadius: isTopRounded ? gridBorderRadius : 0,
            borderTopRightRadius: isTopRounded ? gridBorderRadius : 0,
            borderBottomLeftRadius: isBottomRounded ? gridBorderRadius : 0,
            borderBottomRightRadius: isBottomRounded ? gridBorderRadius : 0,
          },

          // Fixes scrollbar issues for windows / edge
          '.ag-layout-normal > .ag-body-viewport.ag-layout-normal': {
            overflowY: 'overlay' as any,
          },
          '& .no-scrollbar': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },

          '& .no-scrollbar::-webkit-scrollbar': { display: 'none' },
          '& .ag-row-hover': { backgroundColor: '#f7f7f8' },
          '& .ag-row-selected': { backgroundColor: '#e5e5e5' },
          '.ag-statement-total-top .ag-row-footer.ag-row-hover,.ag-statement-total-top .ag-row-footer.ag-row-selected':
            {
              backgroundColor: 'transparent !important',
            },

          // Styling for Master Detail in Payment Detail Drawer PaymentLines Table
          '.ag-details-row': { padding: '0px' },
          '& .ag-payment-details': {
            '.ag-root-wrapper': {
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              border: 'none',
            },
            '.ag-header': { display: 'none' },
            '.ag-layout-auto-height': {
              '.ag-center-cols-clipper, .ag-center-cols-container': {
                minHeight: '100% !important',
              },
            },
          },
          '& .ag-line-exception-details': {
            '.ag-root-wrapper': {
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              border: 'none',
            },
            '.ag-header': { display: 'none' },
            '.ag-layout-auto-height .ag-center-cols-container, .ag-center-cols-clipper':
              {
                minHeight: 'unset',
              },
          },

          // Blur row when limit is set for free users
          '& .ag-row.limit-blurred': {
            filter: 'blur(4px)',
            pointerEvents: 'none',
          },
        },
      },
    };
  }
);

import { useStyles } from './_styles';
import { AgGridReact, AgGridReactProps } from '@finalytic/ui-grid';
import { Box } from '@mantine/core';
import { LegacyRef, forwardRef } from 'react';

type TableTheme = 'alpine';

export type TableStylingProps = {
  isBottomRounded?: boolean;
  isTopRounded?: boolean;
  noBorder?: boolean;
  withMinHeight?: boolean;
  minHeight?: number;
  theme?: TableTheme;
};

type TableProps = {
  rowData?: { [key: string]: string | number }[] | any[] | null;
} & TableStylingProps;

const empty: any[] = [];
// eslint-disable-next-line react/display-name
export const Table = forwardRef<
  AgGridReact,
  AgGridReactProps & TableProps & TableStylingProps
>(
  (
    {
      isBottomRounded = true,
      isTopRounded = true,
      noBorder = false,
      pagination = true,
      withMinHeight = false,
      defaultColDef,
      gridOptions,
      rowData,
      theme = 'alpine',
      minHeight = 0,
      noRowsOverlayComponent: NoRowOverlay,
      ...props
    },
    ref
  ) => {
    const { classes } = useStyles({
      isEmpty:
        withMinHeight || !!minHeight || (rowData ? rowData.length < 1 : true),
      isBottomRounded,
      isTopRounded,
      noBorder,
      minHeight,
      noHeader: props.headerHeight === 0,
      hasRowAutoHeight: (props.columnDefs || [])
        ?.flatMap((c) => Object.keys(c))
        .includes('autoHeight'),
    });

    rowData = rowData || (empty as any);
    return (
      <div
        className={`ag-theme-${theme} ${classes.table}`}
        style={{ width: '100%', height: '100%' }}
      >
        <WorkAround value={rowData}>
          {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
          <AgGridReact
            ref={ref as LegacyRef<AgGridReact>} // Ref for accessing Grid's API
            rowData={rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              resizable: true,
              suppressMovable: true,
              ...defaultColDef,
            }}
            // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            noRowsOverlayComponent={() =>
              NoRowOverlay ? (
                <NoRowOverlay />
              ) : (
                <Box pt={50}>No Rows To Show</Box>
              )
            }
            gridOptions={{
              ...gridOptions,
            }}
            // getRowId={(data) => (data as any).id}
            pagination={pagination}
            suppressRowVirtualisation
            suppressColumnVirtualisation
            enableCellTextSelection
            {...props}
          />
        </WorkAround>
      </div>
    );
  }
);

function WorkAround({ children, value }: { children: any; value: any }) {
  if (
    value &&
    value.length === 1 &&
    value[0] &&
    'id' in value[0] &&
    value[0].id === undefined
  ) {
    return null;
  }
  return children;
}

import {
  DelayedInputSearch,
  LoadingIndicator,
  SelectButton,
} from '../../../components';
import { useColors } from '../../../styles';
import { createUUID } from '../../../utils';
import { SelectItem } from '../_types';
import { sortBy, toTitleCase } from '@finalytic/utils';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Center,
  Divider,
  Group,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

export type PopoverContentProps = {
  onAddCustomValue?: (newValue: SelectItem) => void;
  withSearch?: boolean;
  onSearchInput?: (searchInput: string) => void;
  noOptionsText?: string;
  addOptionText?: (v: string) => string;
  searchPlaceholder?: string;
  popoverHeight?: number;
  sort?: 'asc' | 'desc' | false;
  loading?: boolean;
};

type PopoverContentBaseProps = {
  data: SelectItem[];
  setValue: (value: SelectItem) => void;
  activeValue?: SelectItem;
};

export const PopoverContent = ({
  withSearch,
  setValue,
  activeValue,
  data,
  onAddCustomValue,
  onSearchInput,
  searchPlaceholder,
  addOptionText,
  noOptionsText = 'No options available...',
  popoverHeight = 230,
  sort = 'asc',
  loading,
}: PopoverContentProps & PopoverContentBaseProps) => {
  const { gray } = useColors();

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRefs = useRef<Record<string, HTMLButtonElement>>({});

  // State
  const [filterInput, setFilterInput] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    undefined
  );

  const { sorted, hasGroupedValues, ungroupedKey } = useMemo(() => {
    const hasGroupedValues = data.some((v) => !!v.group);
    const ungroupedKey = 'ungrouped';

    if (sort) {
      const s = sortBy(data, (x) =>
        hasGroupedValues
          ? `${data.findIndex((i) => i.group === x.group) || data.length}.${
              x.label
            }`
          : x.label
      );
      const sortedByDirection = sort === 'asc' ? s : s.reverse();

      return {
        sorted: sortBy(sortedByDirection, (x) => (x?.pinned ? 0 : 1)),
        hasGroupedValues,
        ungroupedKey,
      };
    } else {
      return {
        sorted: sortBy(data, (x) => (x?.pinned ? 0 : 1)),
        hasGroupedValues,
        ungroupedKey,
      };
    }
  }, [data, sort]);

  const { filteredValues, hasPinnedValues } = useMemo(() => {
    const isInSearch = (value: SelectItem): boolean =>
      value.label.toLowerCase().includes(filterInput.toLowerCase()) ||
      value.value.toLowerCase().includes(filterInput.toLowerCase());

    const filteredValues = filterInput
      ? sorted.filter((i) => isInSearch(i))
      : sorted;

    const hasPinnedValues = filteredValues.some((i) => !!i?.pinned);

    return {
      filteredValues,
      hasPinnedValues,
    };
  }, [filterInput, sorted]);

  // Scroll & Keyboard Logic
  const { scrollIntoView, targetRef, scrollableRef } =
    useScrollIntoView<HTMLButtonElement>({
      duration: 0,
      offset: 40,
      cancelable: false,
      isList: true,
    });

  const addCustomValue = (input: string | undefined) => {
    if (onAddCustomValue) {
      onAddCustomValue({
        label: input || '',
        value: createUUID(),
      });
    }
  };

  const scrollSelectedItemIntoView = (scrollDown: boolean) => {
    window.setTimeout(() => {
      targetRef.current =
        itemsRefs.current[
          filteredValues[
            highlightedIndex === undefined ? 0 : highlightedIndex
          ]?.value
        ];
      scrollIntoView({ alignment: scrollDown ? 'end' : 'start' });
    }, 0);
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // if (e.target != containerRef.current) return;
      switch (e.code) {
        case 'Enter':
        case 'Space':
          if (highlightedIndex !== undefined) {
            if (highlightedIndex === -1) {
              addCustomValue(filterInput);
            } else {
              setValue(filteredValues[highlightedIndex]);
            }
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown': {
          if (highlightedIndex === undefined) {
            setHighlightedIndex(onAddCustomValue ? -1 : 0);
            break;
          }
          const isDown = e.code === 'ArrowDown';
          const newValue = (highlightedIndex || 0) + (isDown ? 1 : -1);
          if (
            newValue >= (onAddCustomValue ? -1 : 0) &&
            newValue < filteredValues.length
          ) {
            setHighlightedIndex(newValue);
          }
          scrollSelectedItemIntoView(isDown);
          break;
        }
      }
    };
    containerRef.current?.addEventListener('keydown', handler);

    return () => {
      containerRef.current?.removeEventListener('keydown', handler);
    };
  }, [highlightedIndex, filteredValues]);

  // Booleans
  const showAddButton = useMemo<boolean>(
    () => !!onAddCustomValue,
    [onAddCustomValue]
  );

  const onTextChange = (value: string) => {
    if (highlightedIndex !== undefined) {
      setHighlightedIndex(undefined);
    }
    setFilterInput(value);
  };

  // Side effects
  useEffect(() => {
    if (onSearchInput) onSearchInput(filterInput);
  }, [onSearchInput, filterInput]);

  return (
    <Stack spacing={5} ref={containerRef}>
      {withSearch && (
        <Box>
          <DelayedInputSearch
            mb={!hasPinnedValues && !showAddButton ? 12 : 3}
            searchInput={filterInput}
            setSearchInput={onTextChange}
            reset={() => setFilterInput('')}
            placeholder={searchPlaceholder}
            autoFocus
          />
          {!hasPinnedValues && !showAddButton && <Divider />}
        </Box>
      )}

      <Box
        sx={{
          maxHeight: popoverHeight,
          display: 'flex',
        }}
      >
        <ScrollArea
          viewportRef={scrollableRef}
          style={{
            flex: 1,
          }}
          styles={{
            root: {
              flex: 1,
            },
            // viewport: { paddingBottom: 0 }, // if you offset scrollbars remove bottom horizontal scrollbar
          }}
          // offsetScrollbars
        >
          {loading ? (
            <Center sx={{ minHeight: 60 }}>
              <LoadingIndicator size='sm' />
            </Center>
          ) : (
            <Stack spacing={5}>
              {showAddButton && (
                <>
                  <SelectButton
                    onClick={() => addCustomValue(filterInput)}
                    label={
                      addOptionText
                        ? addOptionText(filterInput)
                        : `Add "${filterInput}"`
                    }
                    leftIcon={
                      <FontAwesomeIcon icon={faPlus} color={'#5C6178'} />
                    }
                    isFocused={-1 === highlightedIndex}
                    wrapText
                  />
                  <Divider />
                </>
              )}
              {filteredValues.length > 0 ? (
                filteredValues.map((item, index, arr) => {
                  return (
                    <Fragment key={item.value}>
                      {hasGroupedValues &&
                        item.group !== arr[index - 1]?.group && (
                          <Group
                            w='100%'
                            spacing='xs'
                            noWrap
                            position='apart'
                            // sx={{ position: 'sticky', top: 0 }}
                          >
                            <Text size='xs' color={gray.dark + 80} weight={500}>
                              {item.group || toTitleCase(ungroupedKey)}
                            </Text>
                            <Divider w='100%' mt={2} />
                          </Group>
                        )}
                      <SelectButton
                        key={item.value}
                        autoFocus={!withSearch && index === 0}
                        onClick={() => setValue(item)}
                        label={item.label}
                        isActive={activeValue?.value === item.value}
                        // onMouseEnter={() => setHighlightedIndex(index)}
                        isFocused={index === highlightedIndex}
                        ref={(node: HTMLButtonElement) => {
                          if (itemsRefs?.current) {
                            itemsRefs.current[item.value] = node;
                          }
                        }}
                        wrapText
                      />
                      {item?.pinned && !arr[index + 1]?.pinned && <Divider />}
                    </Fragment>
                  );
                })
              ) : (
                <Text my={6} align='center' size={13} color='gray' weight={500}>
                  {noOptionsText}
                </Text>
              )}
            </Stack>
          )}
        </ScrollArea>
      </Box>
    </Stack>
  );
};

export const ChevronIcon = ({ color = 'white' }: { color?: string }) => (
  <svg
    width='6'
    height='6'
    viewBox='0 0 6 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <path
        d='M5.16647 2.06786C5.11084 2.01219 5.0449 1.98438 4.96872 1.98438L1.03128 1.98438C0.955058 1.98438 0.889165 2.01219 0.833493 2.06786C0.777821 2.12359 0.750008 2.18949 0.750008 2.26566C0.750008 2.34182 0.777821 2.40771 0.833493 2.4634L2.80222 4.43212C2.85795 4.4878 2.92385 4.51567 3 4.51567C3.07616 4.51567 3.14212 4.4878 3.19774 4.43212L5.16647 2.46339C5.22208 2.40771 5.25 2.34182 5.25 2.26565C5.25 2.18949 5.22208 2.12359 5.16647 2.06786Z'
        fill={color}
      />
    </g>
  </svg>
);

export const StatusIcon = ({
  invertColor = false,
}: {
  invertColor?: boolean;
}) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.5 10.5H3.5C3.23478 10.5 2.98043 10.3946 2.7929 10.2071C2.60536 10.0196 2.5 9.7652 2.5 9.5V2.5C2.5 2.23478 2.60536 1.98043 2.7929 1.79289C2.98043 1.60535 3.23478 1.5 3.5 1.5H6.293C6.4256 1.50003 6.55275 1.55272 6.6465 1.6465L9.3535 4.3535C9.4473 4.44724 9.49995 4.5744 9.5 4.707V9.5C9.5 9.7652 9.39465 10.0196 9.2071 10.2071C9.01955 10.3946 8.7652 10.5 8.5 10.5Z'
      fill={invertColor ? 'white' : '#14171930'}
    />
    <path
      d='M4.5 8.5V7.5M6 8.5V6.5M7.5 8.5V5.5M8.5 10.5H3.5C3.23478 10.5 2.98043 10.3946 2.7929 10.2071C2.60536 10.0195 2.5 9.7652 2.5 9.5V2.5C2.5 2.23478 2.60536 1.98043 2.7929 1.79289C2.98043 1.60535 3.23478 1.5 3.5 1.5H6.293C6.4256 1.50003 6.55275 1.55272 6.6465 1.6465L9.3535 4.3535C9.4473 4.44724 9.49995 4.5744 9.5 4.707V9.5C9.5 9.7652 9.39465 10.0195 9.2071 10.2071C9.01955 10.3946 8.7652 10.5 8.5 10.5Z'
      stroke={invertColor ? 'white' : '#14171930'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 9V7.66667M6 9V6.33333M8 9V5'
      stroke={invertColor ? '#141719' : 'white'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const DateIcon = ({
  invertColor = false,
}: {
  invertColor?: boolean;
}) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5 1.5H9V0.75C9 0.335334 8.66407 0 8.25 0C7.83593 0 7.5 0.335334 7.5 0.75V1.5H4.5V0.75C4.5 0.335334 4.16407 0 3.75 0C3.33593 0 3 0.335334 3 0.75V1.5H1.5C0.671861 1.5 0 2.17186 0 3V10.5C0 11.3281 0.671861 12 1.5 12H10.5C11.3281 12 12 11.3281 12 10.5V3C12 2.17137 11.328 1.5 10.5 1.5ZM10.5 10.5H1.5V5.12461H10.5V10.5Z'
      fill={invertColor ? 'white' : '#14171940'}
    />
  </svg>
);

export const AmountIcon = ({
  invertColor = false,
}: {
  invertColor?: boolean;
}) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      width='12'
      height='12'
      rx='6'
      fill={invertColor ? 'white' : '#14171940'}
    />
    <path
      d='M6 1.9375V2.875'
      stroke={invertColor ? '#141719' : 'white'}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 9.125V10.0625'
      stroke={invertColor ? '#141719' : 'white'}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.1875 4.4375C8.1875 4.23231 8.14708 4.02913 8.06856 3.83956C7.99004 3.64999 7.87495 3.47774 7.72985 3.33265C7.58476 3.18755 7.41251 3.07246 7.22294 2.99394C7.03337 2.91542 6.83019 2.875 6.625 2.875H5.21875C4.80435 2.875 4.40692 3.03962 4.1139 3.33265C3.82087 3.62567 3.65625 4.0231 3.65625 4.4375C3.65625 4.8519 3.82087 5.24933 4.1139 5.54235C4.40692 5.83538 4.80435 6 5.21875 6H6.9375C7.3519 6 7.74933 6.16462 8.04235 6.45765C8.33538 6.75067 8.5 7.1481 8.5 7.5625C8.5 7.9769 8.33538 8.37433 8.04235 8.66735C7.74933 8.96038 7.3519 9.125 6.9375 9.125H5.0625C4.6481 9.125 4.25067 8.96038 3.95765 8.66735C3.66462 8.37433 3.5 7.9769 3.5 7.5625'
      stroke={invertColor ? '#141719' : 'white'}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
